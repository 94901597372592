import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [input, setInput] = useState('');
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [color, setColor] = useState('#000000'); // Estado para a cor do QR Code

  const generateQRCode = () => {
    if (input.trim() === '') {
      toast.error('Por favor, insira um conteúdo para gerar o QR Code.');
    } else {
      setQrCodeValue(input);
      toast.success('QR Code gerado com sucesso!');
    }
  };

  const downloadQRCode = () => {
    const svg = document.getElementById('qrCodeEl');
    const svgData = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'qrcode.svg';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="min-h-screen bg-gray-800 flex flex-col items-center justify-center p-4">
      <ToastContainer />
      <h1 className="text-2xl text-white mb-6">Gerador de QR Code</h1>
      <div className="w-full max-w-md flex flex-col items-center mb-6">
        <input
          type="text"
          placeholder="Insira o conteúdo aqui"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="p-2 rounded mb-4 w-full"
        />
        <div className="flex items-center mb-4 w-full">
          <label htmlFor="colorPicker" className="text-white mr-2">
            Selecione a cor:
          </label>
          <input
            type="color"
            id="colorPicker"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            className="p-2 rounded"
          />
        </div>
        <button
          onClick={generateQRCode}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Gerar QR Code
        </button>
      </div>
      {/* Texto sempre visível */}
      <p className="text-white mb-2">Clique no QR Code para baixar</p>
      {/* Espaço reservado para o preview do QR Code */}
      <div className="w-80 h-80 bg-gray-700 flex items-center justify-center mb-6">
        {qrCodeValue ? (
          <div onClick={downloadQRCode} className="cursor-pointer">
            <QRCodeSVG
              id="qrCodeEl"
              value={qrCodeValue}
              size={256}
              bgColor="transparent"
              fgColor={color}
              includeMargin={false}
              level="Q"
            />
          </div>
        ) : (
          <p className="text-gray-400">O QR Code aparecerá aqui após a geração.</p>
        )}
      </div>
      {/* Link no final da página */}
      <a
        href="https://donation.raulscheidemantel.com"
        className="text-blue-400 hover:underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ajude a manter o site no ar e sem anúncios.
      </a>
    </div>
  );
}

export default App;

